import React, { Fragment } from "react";
import {
  Features2,
  Services3,
  Contacts,
  ParallaxHeader,
  Partners,
} from "../../widgets";
import {Helmet} from 'react-helmet';

import { useCustomState } from "../../../state/state";

export default () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
      <Helmet>
        <title>GagnonGodin Agence de recouvrement inc. | Accueil</title>
        <meta
          name="description"
          content="GagnonGodin Agence de recouvrement inc. offre des services de recouvrement de créances, enquêtes, localisation et gestion de jugements."
        />
      </Helmet>
          <ParallaxHeader></ParallaxHeader>
          <Services3 data={state.data.services} />
          <Features2 data={state.data.features2} />
          <Contacts />
          <Partners data={state.data.partners} />
    </Fragment>
  );
};
