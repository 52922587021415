import React from "react";
import styles from "./Features2.module.scss";
import Layout from "../../layouts/Layout/Layout";
import THEME from "../../../state/theme";

export default ({ data = [] }) => {
  const servicesList = data
    .map((item, index) => (
      <article key={index} className={styles.wrapper}>
        <div
          className={[styles.card, index === 1 && styles.main].join(" ")}
          style={{ borderColor: THEME.color }}
        >
          <i className={item.icon} />
          <h3 style={{ color: THEME.color }}>{item.title}</h3>
          <p>{item.text}</p>
        </div>
      </article>
    ))
    .slice(0, 3);

  return (
    <Layout
      col="3"
      padding
      style={{
        background: "rgb(240,240,240)",
      }}
    >
      <div className={["layout-header", styles.header].join(" ")}>
        <span className="subtitle" style={{ color: THEME.color }}>
          Pourquoi nous choisir?
        </span>
        <h2>On ne peut s'improviser expert du recouvrement.</h2>
        <p className="parargraph">
        GagnonGodin se démarque de la compétition notamment par sa rigueur, son expertise et son honnêteté. Compte tenu du caractère onéreux de ce type de service, il est primordial de s'entourer d'une équipe professionnelle et compétente.
        </p>
        <p className="parargraph" >Nous employons huissiers, avocats et agents de recouvrement combinant plusieurs années d'expériences dans le domaine.</p>
      </div>
      {servicesList}
    </Layout>
  );
};
