const photo = require("../assets/placeholders/photo.jpg");

const place = require("../assets/service-icons/place.svg");
const money = require("../assets/service-icons/money.svg");;
const law = require("../assets/service-icons/law.svg");
const investigation = require("../assets/service-icons/investigation.svg");
const ibv = require("../assets/service-icons/ibv.svg");

export const services = [
  {
    featured: "yes",
    full:
      "GagnonGodin peut recouvrir pour vous toutes vos mauvaises créances que vous ayez ou non une décision ou un jugement. Notre département de recouvrement emploi huissiers, avocats et agents de recouvrement afin de maximiser les chances de percevoir votre dû. GagnonGodin possède une grande expertise en matière de négociation avec les débiteurs en défaut de paiement. Mis à part l'ouverture de dossier, aucun frais ne vous sera facturé si nous ne collectons aucune somme.",
    icon: money,
    id: "recouvrement",
    img: money,
    headerBg: photo,
    steps: [
      {
        text:
          "Communiquez avec notre équipe pour nous faire part de la créance à recouvrir. Nous vous ferons parvenir un mandat de recouvrement détaillant la créance ainsi que tous les frais s'y rattachant.Nous procéderons à l'ouverture de votre dossier suite à la réception du mandat de recouvrement dûment signé par le créancier.",
        title: "Mandat de recouvrement",
      },
      {
        text:
          "Notre équipe prendra contact avec le débiteur afin d'évaluer la capacité ainsi que le désir de ce dernier de convenir à une entente de paiement à l'amiable. Nous nous assurerons que l'entente soit satisfaisante pour vous et réalisable compte tenu des moyens financiers du débiteur. Advenant un refus du débiteur GagnonGodin vous offre plusieurs solutions. (Voir section jugement)",
        title: "Entente de paiement",
      },
      {
        text:
          "Une fois l'entente de paiement convenue avec le débiteur, nous procéderons à la remise des sommes perçues à la fréquence désirée vous assurant ainsi un accès rapide à vos liquidités.",
        title: "Recevez votre argent",
      },
    ],
    subtitle: "Recouvrement à l'amiable de vos créances",
    text:
      "Recouvrement à l'amiable",
    title: "Recouvrement",
  },
  {
    featured: "yes",
    full:
      "Améliorez vos conversions en utilisant des informations financières à jour et exactes sur vos clients. Supprimez le besoin de partage de détails d'identification tout en minimisant les erreurs. De plus, en seulement quelques minutes, il est possible de générer un compte-rendu complet et actuel des transactions et des comportements quotidiens du consommateur.",
    icon: ibv,
    id: "ibv",
    img: ibv,
    headerBg: photo,
    subtitle: "Vérification bancaire instantanée",
    text:
      "IBV - Vérification bancaire instantanée",
    title: "Vérif. Bancaire",
  },
  {
    pricing: {
      underText: "* Pour tous nos plans de localisation, un frais d'ouverture de dossier sera exigé à partir de 39$ +taxes non remboursable. Ce montant est déduit de la facture finale.",
      prices: [
        // {
        //   icon:
        //     "",
        //   price: "129$",
        //   services: {
        //     avaliable: 2,
        //     list: [
        //       "Téléphone",
        //       "Adresse",
        //       "Source de revenu",
        //       "Institution bancaire",
        //     ],
        //   },
        //   title: "Adresse",
        //   orderUrl: '/localisation-form',
        // },
        // {
        //   icon:
        //     "",
        //   price: "149$",
        //   services: {
        //     avaliable: 2,
        //     list: [
        //       "Téléphone",
        //       "Source de revenu",
        //       "Adresse",
        //       "Institution bancaire",
        //     ],
        //   },
        //   title: "Source de revenu",
        //   orderUrl: '/localisation-form',
        // },
        // {
        //   icon:
        //     "",
        //   price: "219$",
        //   services: {
        //     avaliable: 2,
        //     list: [
        //       "Téléphone",
        //       "Institution bancaire",
        //       "Adresse",
        //       "Source de revenu",
        //     ],
        //   },
        //   title: "Institution bancaire",
        //   orderUrl: '/localisation-form',
        // },
        {
          icon:
            "",
          price: "124$",
          priceFrom: true,
          services: {
            avaliable: 4,
            list: [
              "Téléphone",
              "Adresse",
              "Source de revenu",
              "Institution bancaire",
            ],
          },
          title: "Localisation",
          subTitle: 'À partir de',
          orderUrl: '/localisation-form',
        },
      ],
    },
    full:
      "Le service de localisation est primordial dans le processus de recouvrement. GagnonGodin vous propose un service hors pair en matière de localisation de données. Grâce aux dernières technologies utilisées, nous sommes en mesure de retracer l'adresse, la source de revenu ainsi que l'institution bancaire de vos débiteurs.",
    icon: place,
    id: "localisation",
    img: place,
    headerBg: photo,
    steps: [
      {
        text:
          "Communiquez avec notre équipe pour nous faire part de votre demande de localisation et nous vous ferons parvenir le formulaire à compléter.",
        title: "Formulaire de localisation",
      },
      {
        text:
          "Notre équipe procédera aux recherches via nos diverses plateformes de données.",
        title: "Extraction des données",
      },
      {
        text:
          "Sur réception de votre paiement, nous vous ferons parvenir un rapport détaillé contenant l'information recherchée.",
        title: "Rapport",
      },
    ],
    subtitle: "Localisez les données pertinentes de vos débiteurs",
    text:
      "Localisez vos débiteurs",
    title: "Localisation",
  },
  {
    pricing: {
      underText: "Tous nos plans d'enquête sont payables lors de l'ouverture du dossier.",
      prices: [
        {
          icon:
            "",
          price: "79$",
          services: {
            avaliable: 5,
            list: [
              "Dossier criminel",
              "Dossier civil",
              "Bureau de crédit",
              "Vérification propriétaire",
              "Vérification emploi"
            ],
          },
          title: "Pré-locatif",
          priceFrom: true,
          orderUrl: '/localisation-form',
          startFrom: false,
        },
        {
          icon:
            "",
          price: "79$",
          services: {
            avaliable: 5,
            list: [
              "Dossier criminel",
              "Dossier civil",
              "Bureau de crédit",
              "Vérification propriétaire",
              "Vérification emploi"
            ],
          },
          title: "Pré-emploi",
          priceFrom: true,
          orderUrl: '/localisation-form',
          startFrom: false,
        },
        {
          icon:
            "",
          price: "359$",
          services: {
            avaliable: 11,
            list: [
              "Dossier criminel",
              "Dossier civil",
              "Bureau de crédit",
              "Vérification emploi",
              "Portrait financier",
              "Niveau d'endettement",
              "Habitude de paiement",
              "Immatriculation (entreprise)",
              "Fournisseurs (entreprise)",
            ],
          },
          title: "Solvabilité",
          orderUrl: '/localisation-form',
          startFrom: false,
        },
        {
          icon:
            "",
          price: "499$",
          priceFrom: true,
          services: {
            avaliable: 7,
            list: [
              "Bien mobilier",
              "Bien immobilier",
              "Entreprise",
              "Action",
              "Jugement",
              "Véhicule",
              "Créance"
            ],
          },
          title: "Recherche d'actifs",
          subTitle: 'À partir de',
          orderUrl: '/localisation-form',
          startFrom: false,
        },
      ],
    },
    full:
      "Le service d'enquête est essentiel dans le processus contractuel et au niveau du recouvrement. Enquêtez sur vos locataires et employés potentiels pour bien cerner le risque qu'ils pourraient représenter pour votre entreprise et/ou votre parc locatif. Utilisez l'enquête de solvabilité ou la recherche d'actifs afin d'établir une stratégie de recouvrement adéquate et efficace compte tenu des informations que l'on vous fournira.",
    icon: investigation,
    id: "enquete",
    img: investigation,
    headerBg: photo,
    steps: [
      {
        text:
          "Communiquez avec notre équipe pour nous faire part de votre demande d'enquête et sur réception de votre paiement, nous vous ferons parvenir le formulaire à compléter. Pour les enquêtes pré emploi et pré locatif, notez qu'un formulaire doit aussi être complété par la personne enquêtée.",
        title: "Formulaire d'enquête",
      },
      {
        text:
          "Notre équipe procédera aux recherches via nos diverses plateformes de données.",
        title: "Extraction des données",
      },
      {
        text:
          "Nous vous ferons parvenir un rapport détaillé contenant l'information recherchée.",
        title: "Rapport",
      },
    ],
    subtitle: "Un portrait complet pour des décisions éclairées.",
    text:
      "Bilan complet de vos débiteurs",
    title: "Enquête",
  },
  {
    full:
      "Le jugement est le seul choix légal pour percevoir une créance suite à un refus du débiteur de conclure une entente de paiement à l'amiable. Que vous ayez ou non un jugement, GagnonGodin peut vous aider. Notre équipe d'huissiers et d'avocats vous offrent une formule sur mesure vous permettant l'obtention d'un jugement. Par la suite nous procéderons aux mesures d'exécutions les plus propices, selon le cas.",
    icon: law,
    id: "jugement",
    img: law,
    headerBg: photo,
    steps: [
      {
        text:
          "Communiquez avec notre équipe pour nous faire part de la situation avec ou sans jugement.",
        title: "Analyse du besoin",
      },
      {
        text:
          "Si vous possédez un jugement, passez directement à la prochaine étape. Si vous désirez en obtenir un, notre équipe s'occupe de tout. (Mise en demeure, demande introductive d'instance, représentation au tribunal etc.)",
        title: "Obtention du jugement",
      },
      {
        text:
          "Sur réception de votre jugement, un de nos analystes communiquera avec vous afin d'établir le processus de recouvrement forcé.",
        title: "Mesure d'exécution",
      },
    ],
    subtitle: "Une gestion sur mesure de vos jugements",
    text:
      "Gestion de jugements",
    title: "Jugement",
  },
];