import React, { Fragment } from "react";
import { Header, Services2 } from "../../widgets";
import { useCustomState } from "../../../state/state";
import {Helmet} from 'react-helmet';

export default () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
      <Helmet>
        <title>Services</title>
        <meta
          name="description"
          content="Une offre professionnelle qui saura répondre à tous vos besoins. Recouvrement, localisation, enquête et gestion de jugement."
        />
      </Helmet>
      <Header img={state.data.header_bgs.services}>Nos services</Header>
      {/* <ListBlock data={state.data.choose} /> */}
      <Services2 data={state.data.services} />
      {/* <Pricing data={state.data.pricing} /> */}
    </Fragment>
  );
};
