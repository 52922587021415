import React from "react";
import styles from "./Logo.module.scss";
import THEME from "../../../state/theme";

const ggIcon = require('../../../assets/logos/icon.svg');

export default ({ dark }) => (
  <figure
    className={[styles.logo, dark ? styles.dark : styles.light].join(" ")}
    style={{ borderColor: THEME.color }}
    alt="Company logo"
  >
    <img alt="Company logo" src={ggIcon}></img>
    <span className="logo-name">
      GagnonGodin | Agence de recouvrement inc.
    </span>
  </figure>
);
