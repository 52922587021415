import React, { Fragment } from "react";
import {
  Header,
  Process,
  Facts2,
  Portfolio,
  Partners,
} from "../../widgets";
import { useCustomState } from "../../../state/state";
import {Helmet} from 'react-helmet';
import styles from "./About.module.scss";

export default () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
      <Helmet>
        <title>À propos</title>
        <meta
          name="description"
          content="Fondée en 2020, GagnonGodin Agence de recouvrement inc. compte à son actif bon nombre de clients satisfaits partout au Québec."
        />
      </Helmet>
      <Header img={state.data.header_bgs.about}>À propos</Header>
      <Facts2 data={state.data.facts2} />
      <Process data={state.data.process} />
      {/* <Portfolio data={state.data.portfolio} /> */}
      <Partners data={state.data.partners} />
    </Fragment>
  );
};
