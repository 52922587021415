import React, { Fragment } from "react";
import styles from "./ServiceSingle.module.scss";
import { Header, Pricing } from "../../widgets";
import { useParams } from "react-router-dom";
import { useCustomState } from "../../../state/state";
import Layout from "../../layouts/Layout/Layout";
import THEME from "../../../state/theme";

const customPage = require("../../../assets/ibv/custom-page.png");
const demoTable = require("../../../assets/ibv/demotable.png");

export default () => {
  const state = useCustomState()[0];
  const { service_id } = useParams();

  const service = state.data.services.filter(
    (service) => service.id.toString() === service_id
  )[0];

  let showSteps = null;
  if (service.steps) {
    const steps = service.steps.map((item, index) => (
      <div className={styles.wrapper} key={index}>
        <div className={styles.step}>
          <span style={{ background: THEME.color }}>
            {"Étape 0" + (index + 1)}
          </span>
          <h3>{item.title}</h3>
          <p>{item.text}</p>
        </div>
      </div>
    ));
    showSteps = <Layout col="3" padding style={{ background: "rgb(245,245,245)" }}>
      {steps}
    </Layout>
  }

  let ibvSection = null
  if (service.id === 'ibv') {
    ibvSection = <div>
        <Layout col="2" padding style={{ background: "rgb(245,245,245)" }}>

            <div className={styles.info}>
              <div style={{textAlign: 'left'}}>
                <h2>À qui est destiné le service?</h2>
              </div>
              <div style={{textAlign: 'left'}}>
                <h3>- Concessionnaire automobile</h3>
                <h3>- Préteurs (micro-prêt inclus)</h3>
                <h3>- Courtage hypothécaire</h3>
                <h3>- Vous offrez du financement?</h3>
              </div>
            </div>
          <div  className={styles.info}>
            <div style={{textAlign: 'left'}}>
              <h2>Les avantages <strong>GagnonGodin</strong>!</h2>
            </div>
            <div style={{textAlign: 'left'}}>
                <h3>- Aucun dévelopement (programmation)</h3>
                <h3>- Aucune gestion de sécurité</h3>
                <h3>- Aucune maintenance du service</h3>
                <h3>- Page web aux couleurs de votre compagnie</h3>
              </div>
          </div>
        </Layout>
        <Layout col="1" padding>
          <div className={styles.info}>
            <div style={{textAlign: 'center'}}>
              <h3><strong>Contactez-nous pour une démo dès maintenant!</strong></h3>
            </div>
            <div style={{textAlign: 'center'}}>
              <a href="tel:4509405445"><h4>450-940-5445</h4></a>
            </div>
          </div>
        </Layout>
        <Layout col="1" padding style={{ background: "rgb(235, 235, 235)" }}>
          <div className={styles.wrapper}>
            <div style={{marginBottom: '30px'}}>
              <h3><strong>Interface d'authentification sur mesure pour votre clientèle</strong></h3>
              <p>Authentification unique par sms ou par téléphone pour votre consommateur et page personnalisée comprenant le logo de votre compagnie.</p>
            </div>
            <div>
            <img alt={service.title} style={{height:'auto',width:'100%'}} src={ customPage }/>
            </div>
          </div>
        </Layout>
        <Layout col="1" padding style={{ background: "rgb(245,245,245)" }}>
          <div className={styles.wrapper}>
            <div className={styles.wrapper}>
              <div style={{marginBottom: '30px'}}>
                <h3><strong>Rapport complet instantané des enquêtes effectuées</strong></h3>
                <p>Relevés des transactions (90 ou 365 jours) et informations de connexion disponible instantanément.</p>
              </div>
              <img alt={service.title} style={{height:'auto',width:'100%'}} src={ demoTable }/>
            </div>
          </div>
        </Layout>
        <Layout col="1" padding>
          <div style={{textAlign: 'center'}}>
            <h2>F.A.Q.</h2>
          </div>
          <div className={styles.wrapper}>
            <div style={{marginBottom: '30px', marginTop: '30px'}}>
              <h3>Quels renseignements seront obtenus grâce au service de vérifcation bancaire instantanée?</h3>
              <p>Le rapport contient les informations suivantes : ligne de crédit, historique des transactions, revenus d’emploi et employeur.</p>
            </div>
            <hr/>
            <div style={{marginBottom: '30px', marginTop: '30px'}}>
              <h3>Est-ce que le service de référence bancaire est sécuritaire?</h3>
              <p>Oui, bien sûr. Ce service est utilisé par les institutions financières et les prêteurs et est sûr pour les consommateurs.</p>
            </div>
            <hr/>
            <div style={{marginBottom: '30px', marginTop: '30px'}}>
              <h3>Comment assurez-vous la confidentialité de mes données?</h3>
              <p>Nous respectons toutes les mesures relatives à la confidentialité des données et agissons en conformité avec la Loi sur la protection des renseignements personnels dans le secteur privé ainsi que la Loi sur la protection des renseignement.</p>
            </div>
            <hr/>
            <div style={{marginBottom: '30px', marginTop: '30px'}}>
              <h3>Quelles sont les avantages pour le consommateur de procéder par vérification bancaire plutôt que de la façon traditionnelle?</h3>
              <p>Les avantages pour le consommateur sont les suivants : augmente la vitesse de traitement d’une demande de prêt, processus simple sans complexité, aucune pièce justificatives à fournir et 100% en ligne.</p>
            </div>
          </div>
        </Layout>
        <Layout col="1" padding>
          <div className={styles.wrapper}>
            <div style={{textAlign: 'center'}}>
              <h3><strong>Contactez-nous pour une démo dès maintenant!</strong></h3>
            </div>
            <div style={{textAlign: 'center'}}>
            <a href="tel:4509405445"><h4>450-940-5445</h4></a>
            </div>
          </div>
        </Layout>
      </div>
  }

  const pricing = service.pricing ? [<Pricing data={service.pricing} />] : '';

  return (
    <Fragment>
      <Header img={service.headerBg}>{service.title}</Header>
      <Layout col="2" padding>
        <div>
          <img alt={service.title} style={{height:'auto',width:'100%'}} src={ service.img }/>
        </div>
        <div className={styles.info}>
          <span style={{ color: THEME.color }}>Notre service</span>
          <h1>{service.subtitle}</h1>
          <p>{service.full}</p>
        </div>
      </Layout>
      {ibvSection}
      {showSteps}
      {pricing}
    </Fragment>
  );
};
