import React, { useState } from "react";
import styles from "./LocalForm.module.scss";
import { CardButton } from "../../ui";
import { Spinner } from "../../elements";
import ReCAPTCHA from "react-google-recaptcha";

export default ({ style }) => {
  const [sending, setSending] = useState(false);
  const [status, setStatus] = useState("");
  const [error, setError] = useState(false);

  // Client side
  const [name, setName] = useState("");
  const [adresse, setAdresse] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // Researched side
  const [rName, setRName] = useState("");
  const [rBirth, setRBirth] = useState("");
  const [rAdresse, setRAdresse] = useState("");
  const [rPhone, setRPhone] = useState("");
  const [rOldAdresse, setROldAdresse] = useState("");
  const [rOldPhone, setROldPhone] = useState("");
  const [rNas, setRNas] = useState("");
  const [rDrive, setRDrive] = useState("");
  const [rMaladie, setRMaladie] = useState("");
  const [rWork, setRWork] = useState("");
  const [rOldWork, setROldWork] = useState("");
  const [rOther, setROther] = useState("");

  const [accepted, setAcceptedCheck] = useState(false);

  const recaptchaRef = React.createRef();

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSubmit = async () => {
    if (name === "") {
      setStatus("Nom est incomplet");
      setError(true);
      return;
    }

    if ( adresse === "") {
      setStatus("Adresse incomplète");
      setError(true);
      return;
    }

    if (!validateEmail(email)) {
      setStatus("Adresse courriel incorect");
      setError(true);
      return;
    }

    if (!phone.match(/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/)) {
      setStatus("Entrez un numéro de téléphone valide");
      setError(true);
      return;
    }

    if (rNas.length > 0 && !rNas.match(/^(\d{3}-\d{3}-\d{3})|(\d{9})$/)) {
      setStatus("Numéro d'assurance social invalide");
      setError(true);
    }
    setSending(true);

    const recaptchaValue = recaptchaRef.current.getValue();

    // if(res.success) {
    //   setError(false);
    //   setStatus("Merci de nous avoir contacté");
    //   resetForm();
    // } else {
    //   setError(true);
    //   setStatus("Une erreur est survenue. Merci de ressayer");
    // }

    setSending(false);
  };

  const resetForm = () => {
    setName("");
    setAdresse("");
    setEmail("");
    setPhone("");
    setRName("");
    setRBirth("");
    setRAdresse("");
    setRPhone("");
    setROldAdresse("");
    setROldPhone("");
    setRNas("");
    setRDrive("");
    setRMaladie("");
    setRWork("");
    setROldWork("");
    setROther("");
    setAcceptedCheck(false);
  };

  const loading = sending && (
    <div className={styles.loading}>
      <Spinner />
    </div>
  );

  return (
    <form style={style} className={styles.wrapper}>
      {loading}
      <CardButton
        click={onSubmit}
        btn_after="&#xf107;"
        btn_text="Envoyer"
        btn_type="solid-color-tb"
        btn_hoverType="solid-gray-tb"
        btn_align="stretch"
        padding
      >
        <div className={styles.contact_form}>
          <h4>Information client</h4>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Nom complet *"
          />
           <input
            value={adresse}
            onChange={(e) => setAdresse(e.target.value)}
            type="text"
            placeholder="Adresse *"
          />
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Adresse courriel *"
          />
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            placeholder="Téléphone *"
          />

          <h4>Personne recherché</h4>
          <input
            value={rName}
            onChange={(e) => setRName(e.target.value)}
            type="text"
            placeholder="Nom complet *"
          />
          <input
            value={rBirth}
            onChange={(e) => setRBirth(e.target.value)}
            type="text"
            placeholder="Date de naissance"
          />
          <input
            value={rAdresse}
            onChange={(e) => setRAdresse(e.target.value)}
            type="text"
            placeholder="Adresse complete"
          />
          <input
            value={rPhone}
            onChange={(e) => setRPhone(e.target.value)}
            type="text"
            placeholder="Téléphone"
          />
          <textarea
            value={rOldAdresse}
            onChange={(e) => setROldAdresse(e.target.value)}
            rows="10"
            placeholder="Anciennes adresses (une par ligne)"
          />
          <textarea
            value={rOldPhone}
            onChange={(e) => setROldPhone(e.target.value)}
            rows="10"
            placeholder="Anciens numéros de téléphone (un par ligne)"
          />
          <input
            value={rNas}
            onChange={(e) => setRNas(e.target.value)}
            type="text"
            placeholder="Numéro d'assurance sociale"
          />
          <input
            value={rDrive}
            onChange={(e) => setRDrive(e.target.value)}
            type="text"
            placeholder="Numéro de permis de conduire"
          />
          <input
            value={rMaladie}
            onChange={(e) => setRMaladie(e.target.value)}
            type="text"
            placeholder="Numéro d'assurance maladie"
          />
          <input
            value={rWork}
            onChange={(e) => setRWork(e.target.value)}
            type="text"
            placeholder="Emploi"
          />
          <textarea
            value={rOldWork}
            onChange={(e) => setROldWork(e.target.value)}
            rows="10"
            placeholder="Emplois antérieurs (un par ligne)"
          />
          <textarea
            value={rOther}
            onChange={(e) => setROther(e.target.value)}
            rows="10"
            placeholder="Autres informations"
          />
          <label className={styles.checkbox_group}>
            <p>J'accepte et confirme avoir lue les <a target='blank' href="#">termes et conditions</a></p>
            <input
              onChange={() => setAcceptedCheck(!accepted)}
              type="checkbox"
              checked={accepted}
            />
            <span className={styles.checkmark} />
          </label>
        </div>
        <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LcFb_8UAAAAAIFQ6Uyi8NzbBxVZo2_gJgUFxbfA"
        />
        <span className={[styles.status, error && styles.error].join(" ")}>
            {status}
          </span>
      </CardButton>
    </form>
  );
};
