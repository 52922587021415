import React from "react";
import styles from "./Pricing.module.scss";
import Layout from "../../layouts/Layout/Layout";
import { CardButton } from "../../ui";
import THEME from "../../../state/theme";
import { useHistory } from "react-router-dom";

export default ({ data = [] }) => {

  const history = useHistory();

  const pricingList = data.prices.map((item, index) => (
    <article key={index}>
      <CardButton
        btn_text={'Nous joindre'}
        animation="slide-bottom"
        background={index === 3 ? THEME.color : "rgb(240,240,240)"}
        btn_align="center"
        btn_type={index === 3 ? "solid-white-tb" : "solid-color-tb"}
        btn_hoverType={index === 3 ? "outline-color-tb" : "solid-white-tb"}
        click={() => history.push({
          pathname: `/contacts/`,
        })}
      >
        <div className={styles.pricing} style={{ borderColor: THEME.color }}>
          <div className={styles.price}>
            <h4>{item.title}</h4>
            <p>{item.priceFrom ? 'à partir de' : ''}</p>
            <span>{item.price}</span>
            <p>par dossier</p>
          </div>
          {/* <div className={styles.info}>i</div> */}
          <div className={styles.info}>
            <ul>
              {item.services.list.map((listItem, listIndex) => {
                if (item.services.avaliable > listIndex) {
                  return (
                    <li key={listIndex}>
                      <i className="las la-check" />
                      <b>{listItem}</b>
                    </li>
                  );
                } else {
                  return (
                    <li key={listIndex}>
                      <i className="las la-times" />
                      {listItem}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </CardButton>
    </article>
  ));

  return (
    <Layout col="4" padding>
      <div className="layout-header">
        <span className="subtitle" style={{ color: THEME.color }}>
          Plans offerts
        </span>
        <h2>Plan personnalisable</h2>
      </div>
      {pricingList}
      <div className={["layout-footer", styles.footer].join(" ")}>
        <p>
          {data.underText}
        </p>
      </div>
    </Layout>
  );
};
