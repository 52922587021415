import React, { Fragment } from "react";
import { Header } from "../../widgets";
import Localisation from "../../widgets/Localisation/Localisation";
import { useLocation } from "react-router-dom";
const photo = require("../../../assets/placeholders/photo.jpg");

export default () => {
  const location = useLocation();
  console.log(location);
  return (
    <Fragment>
      <Header img={photo} >Formulaire de localisation</Header>
      <Localisation />
    </Fragment>
  );
};
