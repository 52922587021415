import React from "react";
import styles from "./Facts2.module.scss";
import { Layout } from "../../layouts";
import { Button } from "../../elements";
import useHover from "../../../utilities/useHover";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";

export default ({ data = [] }) => {
  const actions = useCustomState()[1];

  const cardList = data.map((item, index) => (
    <Card
      key={index}
      icon={item.icon}
      title={item.title}
      lead={item.lead}
      text={item.text}
    />
  ));
  return (
    <Layout
      col="3"
      style={{ background: "rgb(245,245,245)"}}
    >
      <div className={["layout-header", styles.header].join(" ")}>
        <span className="subtitle" style={{ color: THEME.color }}>
          Statistiques
        </span>
        <h2>Les résultats de notre compagnie en chiffre</h2>
        <p>Fondée en 2020, GagnonGodin Agence de recouvrement inc. compte à son actif bon nombre de clients satisfaits partout au Québec.</p>
        <p>
        Nous avons déjà traité plusieurs centaines de demandes provenant de divers clients (notaires, avocats, huissiers, gestionnaires d'immeuble, entreprises, particuliers et organismes publics) avec un taux de réussite qui ne cesse de croitre.
        </p>
        <p>
        Nous avons déjà traité plusieurs centaines de demandes provenant de divers clients (notaires, avocats, huissiers, gestionnaires d'immeuble, entreprises, particuliers et organismes publics) avec un taux de réussite qui ne cesse de croitre.
        </p>
      </div>
      {cardList}

      <div
        style={{ background: THEME.color }}
        className={["layout-footer", styles.footer].join(" ")}
      >
        <h2>
        <b>Devenez vous aussi un client satisfait dès maintenant!</b>
        </h2>
        <Button
          click={() => actions.toogleModal()}
          round
          after="&#xf105;"
          type="solid-white-tb"
          hoverType="outline-white-tw"
        >
          Nous joindre
        </Button>
      </div>
    </Layout>
  );
};

const Card = ({ icon, title, lead, text }) => {
  const [hoverRef, isHovered] = useHover();

  return (
    <article className={styles.wrapper}>
      <div ref={hoverRef} className={styles.card}>
        <figure>
          <img src={icon} alt="icon" />
        </figure>
        <h2 style={{ color: isHovered && THEME.color }}>{title}</h2>
        <span style={{ color: THEME.color }}>{lead}</span>
        <p>{text}</p>
      </div>
    </article>
  );
};
