export default function createLead(params) {
  console.log('test');
  return post(
    'POST',
    '/api/www/lead',
    params
  )
}


const post = async (method, path, body = {}) => {
  const response = await fetch(path, {
    method: method,
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  });
  return response.json();
}