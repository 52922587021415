import React, { useState } from "react";
import styles from "./ContactForm.module.scss";
import { CardButton } from "../../ui";
import { Spinner } from "../../elements";
import createLead from "../../../utilities/backendClient";
import ReCAPTCHA from "react-google-recaptcha";

export default ({ style }) => {
  const [sending, setSending] = useState(false);
  const [status, setStatus] = useState("");
  const [error, setError] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [preferedCheck, setPreferedCheck] = useState(false);

  const recaptchaRef = React.createRef();

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSubmit = async () => {
    if (name === "") {
      setStatus("Entrer votre nom");
      setError(true);
      return;
    }

    if (!validateEmail(email)) {
      setStatus("Adresse courriel incorect");
      setError(true);
      return;
    }

    if (!phone.match(/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/)) {
      setStatus("Entrez un numéro de téléphone valide");
      setError(true);
      return;
    }

    if (message === "") {
      setStatus("Veuillez entrer un message");
      setError(true);
      return;
    }
    setSending(true);

    const recaptchaValue = recaptchaRef.current.getValue();

    const res = await createLead({
      name: name,
      email: email,
      phoneNumber: phone,
      text: message,
      phonePreference: preferedCheck,
      gToken: recaptchaValue,
    });

    if(res.success) {
      setError(false);
      setStatus("Merci de nous avoir contacté");
    } else {
      setError(true);
      setStatus("Une erreur est survenue. Merci de ressayer");
    }

    resetForm();
    setSending(false);
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setPreferedCheck(false);
  };

  const loading = sending && (
    <div className={styles.loading}>
      <Spinner />
    </div>
  );

  return (
    <form style={style} className={styles.wrapper}>
      {loading}
      <CardButton
        click={onSubmit}
        btn_after="&#xf107;"
        btn_text="Envoyer"
        btn_type="solid-color-tb"
        btn_hoverType="solid-gray-tb"
        btn_align="stretch"
        padding
      >
        <div className={styles.contact_form}>
          <h4>Nous contacter:</h4>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Nom complet *"
          />
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Adresse courriel *"
          />
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            placeholder="Téléphone *"
          />
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="10"
            placeholder="Message"
          />
          <label className={styles.checkbox_group}>
            <p>Préférence de contact par téléphone</p>
            <input
              onChange={() => setPreferedCheck(!preferedCheck)}
              type="checkbox"
              checked={preferedCheck}
            />
            <span className={styles.checkmark} />
          </label>
          <br />
          <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LcFb_8UAAAAAIFQ6Uyi8NzbBxVZo2_gJgUFxbfA"
          />
          <span className={[styles.status, error && styles.error].join(" ")}>
            {status}
          </span>
        </div>
      </CardButton>
    </form>
  );
};
