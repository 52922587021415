import React, { useEffect, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import {isMobile} from 'react-device-detect';

import {
  Home,
  About,
  Services,
  Contacts,
  ServiceSingle,
  LocalisationForm,
} from "./components/pages";
import { Header, Sidebar, Footer } from "./components/layouts";
import { ModalForm, ModalVideo, RequestForm } from "./components/ui";
import { Spinner } from "./components/elements";
import { useCustomState } from "./state/state";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import TagManager from 'react-gtm-module'

export default () => {
  const [state, actions] = useCustomState();

  const tagManagerArgs = {
    gtmId: 'GTM-M5VDKRN'
  }

  TagManager.initialize(tagManagerArgs)

  useEffect(() => {
    actions.fetch();
  }, [actions]);

  let app = state.error ? <p>Can't load page</p> : <Spinner fluid />;

  if (state.data) {
    app = (
        <Fragment>
          <Sidebar data={state.data.menu} />
          <ModalForm />
          <RequestForm />
          <Header data={state.data.menu} />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/a-propos" exact component={About} />
            <Route path="/services" exact component={Services} />
            <Route path="/contacts" exact component={Contacts} />
            <Route
              path="/services/:service_id"
              exact
              component={ServiceSingle}
            />
            <Route path="/localisation-form" exact component={LocalisationForm} />
            {/* catch all route  */}
            <Route component={Home} />
          </Switch>
          <MessengerCustomerChat
            pageId="103843797922035"
            appId="306349510393025"
            htmlRef={window.location.pathname}
            language='fr_FR'
            shouldShowDialog={!isMobile}
            loggedOutGreeting="Ecrivez-nous en direct! Comment pouvons-nous vous aider?"
            version="7.0"
          />,
          <Footer />
        </Fragment>
    );
  }

  return <Fragment>{app}</Fragment>;
};
