import React from "react";
import styles from "./Contacts.module.scss";
import Layout from "../../layouts/Layout/Layout";
import { ContactForm } from "../../ui";
import { useCustomState } from "../../../state/state";

export default () => {
  const state = useCustomState()[0];

  return (
    <Layout
      style={{
        background:
          "url(" + state.data.header_bgs.contacts_w + ") center/cover",
      }}
      mode="dark"
      col="2"
      padding
    >
      <ContactForm />
      <div className={styles.wrapper}>
        <div className={styles.contact_info}>
          <h2>Confiez-nous votre dossier dès aujourd'hui !</h2>
          <p>
          N'hésitez pas à communiquer avec nous pour toutes questions ou pour de plus amples renseignements sur nos services. Un de nos agents se fera le plaisir de vous répondre dans les meilleurs délais et selon le moyen de communication de votre choix.
          </p>
        </div>
      </div>
    </Layout>
  );
};
