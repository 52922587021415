import React, { useState, useEffect, useRef } from "react";
import styles from "./Services3.module.scss";
import Layout from "../../layouts/Layout/Layout";
import THEME from "../../../state/theme";
import { useHistory } from "react-router-dom";

import { Button } from "../../elements";

export default ({ data = [] }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const history = useHistory();

  useEffect(() => {
    setHeight(ref.current.clientHeight);
    window.addEventListener("resize", resizeListener);
    return window.removeEventListener("resize", resizeListener);
  }, []);

  const resizeListener = () => {
    setHeight(ref.current.clientHeight);
  };

  let listStatistic = data
    .map((item, index) => (
      <div
        key={index}
        className={styles.wrapper}
        style={{
          borderColor: THEME.color,
        }}
        onClick={() => history.push(`/services/${item.id}`)}
      >

        <div className={styles.card} >
          <div className={styles.icon}>
            <img src={item.icon} alt="card_icon" />
          </div>

          <h3
            style={{
              color: THEME.color,
            }}
          >
            {item.title}
          </h3>
          <p>{item.text.replace(/^(.{60}[^\s]*).*/, "$1")}</p>
        </div>
      </div>
    ))
    .slice(0, 4);

  return (
    <div className={styles.stat_area}>
      <Layout
        style={{
          transform: "translateY(-50%)",
        }}
        col="1"
      >
        <div className={styles.statistic} ref={ref}>
          {listStatistic}
        </div>
      </Layout>
      <span
        className={styles.negative_space}
        style={{
          height: height / 2 + "px",
          marginTop: -height + "px",
        }}
      />
    </div>
  );
};
