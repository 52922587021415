import React, { Fragment } from "react";
import styles from "./Contacts2.module.scss";
import Layout from "../../layouts/Layout/Layout";
import { ContactForm } from "../../ui";
import { AddressBox } from "../../elements";
import THEME from "../../../state/theme";

export default () => {
  return (
    <Fragment>
      <div className={styles.header}>
        <span className="subtitle" style={{ color: THEME.color }}>
          N'hésitez pas à nous contacter.
        </span>
        <h2>Votre satisfaction, notre priorité.</h2>
        <p>
          GagnonGodin porte une attention particulière à la satisfaction de sa clientèle. Faites nous part de vos suggestions, d'un service que vous voudriez que l'on offre ou d'une problématique à laquelle vous faite face. 
        </p>
      </div>
      <Layout col="4">
        <AddressBox
          icon="las la-phone"
          title="Téléphone"
          text="+1 (450) 940-5445"
          link="tel:14509405445"
        />
        <AddressBox
          icon="la la-envelope-open"
          title="Courriel"
          text="info@gagnongodin.ca"
          link="mailto:info@gagnongodin.ca"
        />
        <AddressBox
          icon="la la-home"
          title="Adresse"
          text="3115 Boulevard de la Pinière, #200
          Terrebonne J6X 4P7"
          link="https://g.page/gagnongodin"
        />
        <AddressBox
          icon="las la-clock"
          title="Horaire"
          text="Lun-Ven | 9:00h @ 17:00h"
          link="https://g.page/gagnongodin"
        />
        <span className={styles.divider} style={{ borderColor: THEME.color }} />
      </Layout>

      <Layout col="2">
        <div className={styles.wrapper}>
          <ContactForm style={{ border: "1px solid #eee" }} />
        </div>
        <div className={styles.info}>
          <i
            className="las la-long-arrow-alt-right"
            style={{ color: THEME.color }}
          />
          <h2>Confiez-nous votre dossier dès aujourd'hui !</h2>
          <p>
            N'hésitez pas à communiquer avec nous pour toutes questions ou pour de plus amples renseignements sur nos services. Un de nos agents se fera le plaisir de vous répondre dans les meilleurs délais et selon le moyen de communication de votre choix.
          </p>
        </div>
        <br />
      </Layout>
      <div className={styles.law}>
        <h2>Loi 25</h2>
        <p>
        <div>
          À des fins de transparence et conformément à la Loi sur la protection des renseignements personnels dans le secteur privé, GagnonGodin vous avise que :
        </div>        
        <div>-Nous détenons des renseignements personnels sur autrui;</div>
        <div>-Nous communiquons à nos cocontractants des rapports de crédit au sujet du caractère, de la réputation et de la solvabilité des personnes concernées par ces renseignements personnels;</div>
        <div>- Nous recevons des communications de nos cocontractants de renseignements personnels sur autrui.</div>
        <br />
        <div>En tant que personne concernée, vous avez le droit d’accéder et/ou de rectifier un renseignement personnel vous concernant.</div>
        <div>Pour ce faire, veuillez SVP nous faire parvenir votre demande de consultation par écrit, en justifiant votre identité et nous la transmettre par courriel ou par la poste aux coordonnés ci-dessous.</div>
        <div> GagnonGodin | Agence de recouvrement Inc. 3115 boul. de la Pinière, suite 200, Terrebonne (Québec) J6X 4P7 A/S M. Jonathan Gagnon, Responsable de la protection des renseignements personnels  450-940-5445 | info@gagnongodin.ca</div>
        <br />
        <div>Le responsable de la protection des renseignements personnels répondra avec transparence afin d’informer le requérant de l’existence d’un dossier chez nous.</div>
        <div>Le cas échéant, le responsable confirmera l’identité de la personne concernée et l’informera des modalités afin de lui permettre la consultation.</div>
        <div>Les consultations doivent obligatoirement être fait en présentiel, à nos bureaux, et pendant les heures d’ouvertures (certaines exceptions pourraient survenir (ex. personne à mobilité réduite)).</div>
        <div>Une copie complète dudit dossier, sur support papier, sera remise à la personne concernée afin de permettre la rectification.</div>
        <div>La personne concernée peut aussi faire supprimer un renseignement personnel la concernant si sa collecte n’est pas autorisée par la loi.</div>
        </p>
      </div>
    </Fragment>
  );
};
