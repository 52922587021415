import React, { Fragment } from "react";
import { Header, Contacts2 } from "../../widgets";
import { useCustomState } from "../../../state/state";
import {Helmet} from 'react-helmet';

export default () => {
  const state = useCustomState()[0];

  return (
    <Fragment>
      <Helmet>
        <title>Nous joindre</title>
        <meta
          name="description"
          content="N'hésitez pas à communiquer avec nous pour toutes questions ou pour de plus amples renseignements sur nos services."
        />
      </Helmet>
      <Header img={state.data.header_bgs.contacts}>Nous joindre</Header>
      <Contacts2 />
    </Fragment>
  );
};
