import React, { Fragment } from "react";
import styles from "./Localisation.module.scss";
import Layout from "../../layouts/Layout/Layout";
import { LocalForm } from "../../ui";
import THEME from "../../../state/theme";

export default () => {
  return (
    <Fragment>
      <div className={styles.header}>
        <span className="subtitle" style={{ color: THEME.color }}>
          Formulaire de localisation
        </span>
        <p>
          Un texte explicatif de ce qui va se passé avec la recherche, sera-t-il contacté etc.
        </p>
      </div>

      <Layout col="12">
        <div className={styles.wrapper}>
          <LocalForm style={{ border: "1px solid #eee" }} />
        </div>
      </Layout>
    </Fragment>
  );
};
