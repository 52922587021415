const photo = require("../assets/slide-images/montreal.jpg");

export const slides = [
  {
    image: photo,
    short:
      "Vos problèmes, nos solutions.",
    title: "Agence de recouvrement",
    user_id: "2",
    button: {
      title: "En savoir plus",
      url: "a-propos",
    }
  }
]