import React from "react";
import styles from "./ParallaxHeader.module.scss";
import { Layout } from "../../layouts";
import { Button } from "../../elements";
import { useHistory } from "react-router-dom";

export default () => {
  const photo = require("../../../assets/slide-images/montreal.jpg");
  const history = useHistory();

  return (
    <div
      className={styles.parallax}
      style={{
        backgroundImage: "url(" + photo + ")",
      }}
    >
      <Layout col="1">
        <div className={styles.intro}>
          <p className="small-txt">
            <a href="tel:+14509405445"><span className="las la-phone"></span> +1 (450) 940-5445</a> | <b></b><a href="mailto:info@gagnongodin.ca"><span className="la la-envelope-open"></span> info@gagnongodin.ca</a>
          </p>
          <h1>
            Le Recouvrement,{" "}
            <b>
              <br /> Autrement.
            </b>{" "}
          </h1>
          <p>Agents de recouvrement | Avocats | Huissiers</p>
          <Button
            click={() => history.push('/services')}
            hoverType="solid-white-tb"
          >
            En savoir plus
          </Button>
        </div>
      </Layout>
    </div>
  );
};
